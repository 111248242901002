import React from 'react';

const TimeToSwitchFAQ = () => ([
    {
      title: 'What is remortgaging, and why should I consider it?',
      description: (
        <>
          Remortgaging is the process of switching your current mortgage to a new deal with the same lender or a new lender.<br/><br/>
          Remortgaging usually happens when the mortgage term comes to an end, or you may want to remortgage to release equity, move to a better interest rate or move homes. However, remortgaging before the end of your term means you are likely to face an early repayment charge.
        </>
      ),
    },
    {
      title: 'How do I know when it\'s the right time to remortgage?',
      description: (
        <>
          The best time to remortgage is typically when your existing deal is ending or if interest rates have dropped since you took out your mortgage.<br/><br/>
          Usually, your current lender will get in touch and let you know you can remortgage.<br/><br/>
          It's a good idea to start looking at other mortgage deals about 3-6 months before your current deal expires as if you don't remortgage before your deal expires you will be put on the lender's standard variable rate (SVR) which is more expensive.
        </>
      ),
    },
    {
      title: 'How soon can you remortgage before fixed rate ends?',
      description: (
        <>
          Ideally, you want to remortgage a few months before your current fixed rate deal ends so that you're already on your new deal before the end of the terms and avoid paying any early repayment charge. You can secure a new deal six months before the end of the fixed rate period, so it's best to start looking around then.<br/><br/>
          In some cases, you may want to remortgage from your existing mortgage deal before the end of your fixed period ends to get a better deal and pay lower monthly mortgage repayments, release cash, move home, and consolidate debt. In this case, there are likely to be costs involved so you would need to consider any exit fee you will be charged to weigh up what's the right decision. Speaking to a mortgage broker can help make sure you're making the right decision.
        </>
      ),
    },
    {
      title: 'What is an early repayment charge (ERC)?',
      description: (
        <>
          An early repayment charge is a penalty that is charged by the lender.<br/><br/>
          These charges are incurred when you exit early on a fixed deal, before the end of your mortgage term pay off your mortgage faster or go over your overpayment threshold by making an early repayment on your mortgage.
        </>
      ),
    },
    {
      title: 'Are there fees when remortgaging?',
      description: (
        <>
        When you remortgage to a new lender, there can be fees such as arrangement fees, valuation fees, legal fees, and potential exit fees from your current lender. Be sure to factor in these costs when comparing new deals.<br /><br />
        It's also worth checking with your existing lender if there are any early repayment charges if you're considering moving to a new mortgage deal early.<br /><br />
        Although mortgage product transfers tend to not have any fees associated, it's best to check with a mortgage broker or the legal documents to make sure there aren't any hidden fees.
        </>
      ),
    },
    {
      title: 'What is a fixed-rate mortgage?',
      description: (
        <>
        A fixed-rate mortgage is where you take out a mortgage on a set interest rate which will stay the same for the duration of the fixed rate mortgage deal. A fixed-rate mortgage deal is a popular option as it means you can budget easily and you don't run the risk of your monthly repayments changing for the duration of the fixed-rate mortgage.<br /><br />
        Exiting a fixed-rate mortgage early will result in an early repayment charge, which is a penalty from the lender for remortgaging early.<br /><br />
        The disadvantage of a fixed-rate mortgage is that if interest rates fall you won't benefit from lower mortgage rates as your interest on your fixed rate mortgage deal will stay the same.
        </>
      ),
    },
    {
      title: 'What type of mortgage deal is right for me?',
      description: (
        <>
        There are different types of mortgages available and deciding what is right for you can be hard and confusing.<br /><br />
        The type of mortgage depends on your personal circumstances and preferences so it's best to speak to a mortgage broker to understand what the best option is for you.<br /><br />
        As a guideline, usually, if you are looking for security in your monthly payments fixed-rate mortgages are a good option, whereas if you're looking for more flexibility then a variable-rate mortgage could be more suitable.<br /><br />
        Different mortgage lenders offer different types of mortgages so it's wise to shop around to understand what is available out there for you.
        </>
      ),
    },
  ]);

export default TimeToSwitchFAQ;
